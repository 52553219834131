import 'normalize.css'
import 'locomotive-scroll/dist/locomotive-scroll.css'
import './styles/main.scss'

import LocomotiveScroll from 'locomotive-scroll';

// import Rellax from 'rellax'
// import Scrollbar from 'smooth-scrollbar'

// const rellax = new Rellax('.rellax');

// Scrollbar.init(document.querySelector('#wrap'));

const scroll = new LocomotiveScroll({
  el: document.querySelector('[data-scroll-container]'),
  smooth: true
});